import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import DismissibleControlled from '../../examples/Alert/DismissibleControlled';
import ReactPlayground from '../../components/ReactPlayground';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "why-react-bootstrap"
    }}>{`Why React-Bootstrap?`}</h1>
    <p>{`React-Bootstrap is a complete re-implementation of the
Bootstrap components using React. It has `}<strong parentName="p">{`no dependency
on either `}<inlineCode parentName="strong">{`bootstrap.js`}</inlineCode>{` or jQuery.`}</strong>{` If you have React
setup and React-Bootstrap installed, you have everything
you need. `}</p>
    <p>{`Methods and events using jQuery is done imperatively
by directly manipulating the DOM. In contrast, React
uses updates to the state to update the virtual DOM.
In this way, React-Bootstrap provides a more reliable
solution by incorporating Bootstrap functionality into
React's virtual DOM. Below are a few examples of how
React-Bootstrap components differ from Bootstrap.`}</p>
    <h2 {...{
      "id": "a-simple-react-component"
    }}>{`A Simple React Component`}</h2>
    <p>{`The CSS and details of Bootstrap components are rather
opinionated and lengthy. React-Bootstrap simplifies
this by condensing the original Bootstrap into React-styled
components. `}</p>
    <h3 {...{
      "id": "bootstrap"
    }}>{`Bootstrap`}</h3>
    <pre><code parentName="pre" {...{}}>{`import React from 'react';

function Example()  {
  return (
    <div class="alert alert-danger alert-dismissible fade show" role="alert">
      <strong>Oh snap! You got an error!</strong> 
      <p> 
        Change this and that and try again.
      </p>
      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  )
}

`}</code></pre>
    <h3 {...{
      "id": "react-bootstrap"
    }}>{`React-Bootstrap`}</h3>
    <pre><code parentName="pre" {...{}}>{`import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';

function Example() {
  return (
    <Alert dismissible variant="danger">
      <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
      <p>
        Change this and that and try again.
      </p>
    </Alert>
  )
}
`}</code></pre>
    <h2 {...{
      "id": "bootstrap-with-state"
    }}>{`Bootstrap with state`}</h2>
    <p>{`Since React-Bootstrap is built with React Javascript, state
can be passed within React-Bootstrap components as a prop.
It also makes it easier to manage the state as updates are
made using React’s state instead of directly manipulating
the state of the DOM. This also gives a lot of flexibility
when creating more complex components.`}</p>
    <h3 {...{
      "id": "react-bootstrap-1"
    }}>{`React-bootstrap`}</h3>
    <ReactPlayground codeText={DismissibleControlled} mdxType="ReactPlayground" />
    <h3 {...{
      "id": "bootstrap-1"
    }}>{`Bootstrap`}</h3>
    <pre><code parentName="pre" {...{}}>{`// HTML
<div class="alert alert-success alert-dismissible fade show firstCollapsible" role="alert">
  <strong>How's it going?!</strong> 
  <p> 
    Duis mollis, est non commodo luctus, nisi erat porttitor ligula,
    eget lacinia odio sem nec elit. Cras mattis consectetur purus sit
    amet fermentum.
  </p>
  <hr/>
  <div class="d-flex justify-content-end">
    <button type="button" class="btn btn-outline-success">Close me ya'll!</button>
  </div>
</div>
<div class="d-flex justify-content-start alert fade show">
    <button type="button" class="btn btn-primary d-none secondCollapsible">Show Alert</button>
</div>

// Javascript
$('.btn-outline-success').on('click', function(e) {
    $('.firstCollapsible').addClass('d-none');
    $('.secondCollapsible').removeClass('d-none');
})

$('.btn-primary').on('click', function(e) {
    $('.firstCollapsible').removeClass('d-none');
    $('.secondCollapsible').addClass('d-none');
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      